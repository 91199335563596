// Import the functions you need from the SDKs you need
import * as App from "firebase/app";
import * as Analytics from "firebase/analytics";
import { Elm } from "./src/Main.elm";
import * as Firestore from "firebase/firestore";
import * as Auth from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDd_O6k98BfMZmzl62UhkJnj1XGIy8t0is",
    authDomain: "utm.veradept.com",
    projectId: "utm0-83739",
    storageBucket: "utm0-83739.appspot.com",
    messagingSenderId: "291357209159",
    appId: "1:291357209159:web:68d744910d861b7b82f762",
    measurementId: "G-G0Q41JZCCN"
};

// Initialize Firebase
const firebaseApp = App.initializeApp(firebaseConfig);
const analytics = Analytics.getAnalytics(firebaseApp);


// var firebaseui = require('firebaseui');
// var ui = new firebaseui.auth.AuthUI(auth);
// ui.start('#firebaseui-auth-container', {
//     signInSuccessWithAuthResult: function (authResult, redirectUrl) {
//         // User successfully signed in.
//         // Return type determines whether we continue the redirect automatically
//         // or whether we leave that to developer to handle.
//         return false;
//     },
//     signInOptions: [
//         Auth.EmailAuthProvider.PROVIDER_ID
//     ],
//     // Other config options...
// });



const db = Firestore.getFirestore(firebaseApp);
// Firestore.connectFirestoreEmulator(db, '127.0.0.1', 8088);


const rnd = new Uint32Array(4);
self.crypto.getRandomValues(rnd);

var elmApp = Elm.Main.init({
    node: document.getElementById("elm-root"),
    flags: {
        seedInts: { int1: rnd[0], int2: rnd[1], int3: rnd[2], int4: rnd[3] },
        window: { width: window.innerWidth, height: window.innerHeight }
    }
})

elmApp.ports.capturePointerFromRawEvent.subscribe(event => {
    event.target.setPointerCapture(event.pointerId)
})

elmApp.ports.saveRailsPort.subscribe(params => {
    const r = { rails: params.rails }
    Firestore.setDoc(Firestore.doc(db, "rails", params.uid), r);
})

elmApp.ports.subscribeToRailsUpdatesPort.subscribe(params => {
    console.log("Subscribing to rails", params)
    Firestore.onSnapshot(Firestore.doc(db, "rails", params.uid),
        rails => {
            const data = { uid: params.uid, rails: (rails.data() ?? {}).rails ?? null }
            console.log("Sending", data)
            elmApp.ports.railsUpdatesPort.send(data)
        }
    )
})

elmApp.ports.watchResizePort.subscribe(params => {
    console.log("Subscribing to resizes for ", params)
    const resizeObserver = new ResizeObserver((entries) => {
        console.log("Got resizes ", params, entries)
        elmApp.ports.notifyResizePort.send({ id: params.id, entry: entries[0] })
    })
    requestAnimationFrame(() => {
        setTimeout(() => {
            console.log("signing up observer: ", document.getElementById(params.id))
            resizeObserver.observe(document.getElementById(params.id))
        })
    })
})

const auth = Auth.getAuth(firebaseApp);

const googleAuthProvider = new Auth.GoogleAuthProvider();

elmApp.ports.signInPort.subscribe(params => {
    Auth.signInWithEmailAndPassword(auth, params.email, params.password).then(result => {
        console.log("Auth result:", result)
    }).catch((error) => {
        console.log("Auth error: (??) ", error)
    });
})


elmApp.ports.signUpPort.subscribe(params => {
    Auth.createUserWithEmailAndPassword(auth, params.email, params.password).then(result => {
        console.log("Auth result:", result)
    }).catch((error) => {
        console.log("Auth error: (??) ", error)
    });
})


Auth.onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        console.log("User is logged in: ", user.email);
        elmApp.ports.userAuthUpdatesPort.send(user)
    } else {
        // User is signed out
        // ...
        console.log("User is now logged out.")
        elmApp.ports.userAuthUpdatesPort.send(null)
    }
});

// Not used from Elm right now, so this errors out.
// elmApp.ports.scrollIntoView.subscribe(id => {
//     requestAnimationFrame(() => {
//         setTimeout(() => {
//             document.getElementById(id).scrollIntoView()
//         })
//     })
// })

elmApp.ports.doGoogleAuth.subscribe(() => {
    console.log("Attempting google login");
    Auth.signInWithRedirect(auth, googleAuthProvider);
})

Auth.getRedirectResult(auth)
    .then((result) => {
        console.log("google login result", result);
        if (result) {

            // This gives you a Google Access Token. You can use it to access Google APIs.
            const credential = Auth.GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            // The signed-in user info.
            const user = result.user;

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }
    }).catch((error) => {
        console.log("google login error", error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = Auth.GoogleAuthProvider.credentialFromError(error);
        // ...
    });

elmApp.ports.signOut.subscribe(() => {
    Auth.signOut(auth)
})