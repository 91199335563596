module Main exposing (..)

import Assignment exposing (Assignment)
import Browser
import Browser.Events
import Color
import Element as El
import Element.Border as Border
import Element.Font as Font
import Element.Input as Input
import Html.Attributes
import Json.Decode as Decode
import Json.Decode.Pipeline as Pipeline
import List.Extra as List
import Ports
import RailStack exposing (..)
import Random
import UUID
import User exposing (User)
import Window exposing (Window)


type alias Model =
    { state : State
    , seeds : UUID.Seeds
    , window : Window
    }


type State
    = WaitingForUserAuthInfo
    | NoUser AuthPageState
    | TryingSignIn AuthPageState
    | Failed String
    | HasUser User StateWithUser


noUser =
    NoUser { emailInputText = "", passwordInputText = "" }


type StateWithUser
    = WaitingForRails
    | Running RailStack.Model


type Msg
    = RailStackMsg RailStack.Msg
    | GotUserAuthInfo (Result Decode.Error (Maybe User))
    | GotUpdatedRails (Result Decode.Error (Maybe (List Assignment)))
    | EmailInputChanged String
    | PasswordInputChanged String
    | GoogleLoginClicked
    | SignIn
    | SignUp
    | SignOutPressed


type alias Flags =
    { seeds : UUID.Seeds
    , window : Window
    }


flagsDecoder =
    Decode.succeed Flags
        |> Pipeline.required "seedInts" seedsDecoder
        |> Pipeline.required "window" Window.decoder


type alias SeedInts =
    { int1 : Random.Seed, int2 : Random.Seed, int3 : Int, int4 : Random.Seed }


createNewUUID model =
    let
        ( uuid, seeds_ ) =
            UUID.step model.seeds
    in
    ( uuid, { model | seeds = seeds_ } )


seedsDecoder : Decode.Decoder UUID.Seeds
seedsDecoder =
    Decode.succeed UUID.Seeds
        |> Pipeline.required "int1" (Decode.int |> Decode.map Random.initialSeed)
        |> Pipeline.required "int2" (Decode.int |> Decode.map Random.initialSeed)
        |> Pipeline.required "int3" (Decode.int |> Decode.map Random.initialSeed)
        |> Pipeline.required "int4" (Decode.int |> Decode.map Random.initialSeed)


type alias AuthPageState =
    { emailInputText : String
    , passwordInputText : String
    }


main =
    Browser.document
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view =
            \model ->
                { title = "UTM"
                , body =
                    [ El.layoutWith
                        { options =
                            [ El.focusStyle
                                { borderColor = Nothing
                                , backgroundColor = Nothing
                                , shadow = Nothing
                                }
                            ]
                        }
                        [ El.width El.fill
                        , El.height El.fill
                        ]
                      <|
                        view model
                    ]
                }
        }


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        railSubs =
            case model.state of
                HasUser _ (Running railStackModel) ->
                    [ RailStack.subscriptions railStackModel ]

                _ ->
                    []
    in
    Sub.batch
        ([ Ports.userAuthUpdates GotUserAuthInfo
         , Ports.railsUpdates GotUpdatedRails
         ]
            ++ (railSubs |> List.map (Sub.map RailStackMsg))
        )


init : Decode.Value -> ( Model, Cmd Msg )
init flagsValue =
    case Decode.decodeValue flagsDecoder flagsValue of
        Ok flags ->
            ( { state = WaitingForUserAuthInfo
              , seeds = flags.seeds
              , window = flags.window
              }
            , Cmd.none
            )

        Err _ ->
            ( { state = Failed "Internal error: Failed to decode flags at startup"
              , seeds =
                    UUID.Seeds (Random.initialSeed 0)
                        (Random.initialSeed 0)
                        (Random.initialSeed 0)
                        (Random.initialSeed 0)
              , window = Window 512 300
              }
            , Cmd.none
            )


unsubscribeRailsFor : User -> Model -> ( Model, Cmd Msg )
unsubscribeRailsFor user model =
    ( { model | state = noUser }, Ports.unsubscribeToRailsUpdates user.uid )


subscribeRailsFor user model =
    ( { model | state = HasUser user WaitingForRails }, Ports.subscribeToRailsUpdates user.uid )


mergeUpdate nextUpdate ( model, cmd ) =
    let
        ( model_, cmd_ ) =
            nextUpdate model
    in
    ( model_, Cmd.batch [ cmd, cmd_ ] )


authFailed model =
    -- TODO: do the ports
    ( { model | state = Failed "Failed to figure out user authentication state" }, Cmd.none )


update msg model =
    case model.state of
        Failed e ->
            ( model, Cmd.none )

        _ ->
            updateNotFailed msg model


updateNotFailed : Msg -> Model -> ( Model, Cmd Msg )
updateNotFailed msg model =
    case msg of
        SignOutPressed ->
            ( model, Ports.signOut () )

        GoogleLoginClicked ->
            ( model, Ports.doGoogleAuth () )

        SignIn ->
            case model.state of
                NoUser authPageModel ->
                    ( { model | state = TryingSignIn authPageModel }
                    , Ports.signIn { email = authPageModel.emailInputText, password = authPageModel.passwordInputText }
                    )

                _ ->
                    ( model, Cmd.none )

        SignUp ->
            case model.state of
                NoUser authPageModel ->
                    ( { model | state = TryingSignIn authPageModel }
                    , Ports.signUp { email = authPageModel.emailInputText, password = authPageModel.passwordInputText }
                    )

                _ ->
                    ( model, Cmd.none )

        EmailInputChanged email ->
            case model.state of
                NoUser authPageModel ->
                    ( { model | state = NoUser { authPageModel | emailInputText = email } }, Cmd.none )

                _ ->
                    ( model, Cmd.none )

        PasswordInputChanged password ->
            case model.state of
                NoUser authPageModel ->
                    ( { model | state = NoUser { authPageModel | passwordInputText = password } }, Cmd.none )

                _ ->
                    ( model, Cmd.none )

        GotUserAuthInfo outcome ->
            case ( outcome, model.state ) of
                ( Ok (Just user), HasUser oldUser _ ) ->
                    if user.uid == oldUser.uid then
                        ( model, Cmd.none )

                    else
                        model
                            |> unsubscribeRailsFor oldUser
                            |> mergeUpdate (subscribeRailsFor user)

                ( Ok (Just user), _ ) ->
                    model |> subscribeRailsFor user

                ( Ok Nothing, HasUser oldUser _ ) ->
                    model |> unsubscribeRailsFor oldUser

                ( Ok Nothing, NoUser _ ) ->
                    ( model, Cmd.none )

                ( Ok Nothing, _ ) ->
                    ( { model | state = noUser }, Cmd.none )

                ( Err err, HasUser oldUser _ ) ->
                    model |> unsubscribeRailsFor oldUser |> mergeUpdate authFailed

                ( Err err, _ ) ->
                    model |> authFailed

        GotUpdatedRails (Ok rails) ->
            let
                rails_ =
                    rails |> Maybe.withDefault RailStack.starterRails |> List.uniqueBy (.task >> .id)
            in
            case model.state of
                HasUser user WaitingForRails ->
                    let
                        ( railStackModel, cmds ) =
                            RailStack.init { rails = rails_, prefix = "railstack" }
                    in
                    ( { model
                        | state = HasUser user (Running railStackModel)
                      }
                    , Cmd.map RailStackMsg cmds
                    )

                HasUser user (Running railStackModel) ->
                    let
                        ( railStackModel_, cmds ) =
                            RailStack.todoGetRidOfThisUpdateRails rails_ railStackModel
                    in
                    ( { model
                        | state = HasUser user (Running railStackModel_)
                      }
                    , Cmd.map RailStackMsg cmds
                    )

                _ ->
                    ( model, Cmd.none )

        GotUpdatedRails (Err err) ->
            ( { model | state = Failed "GotUpdatedRails got an error" }, Cmd.none )

        RailStackMsg railsMsg ->
            case model.state of
                HasUser user (Running railsModel) ->
                    let
                        ( railsModel_, railCmd, model_ ) =
                            RailStack.update createNewUUID model railsMsg railsModel

                        saveRails =
                            if railsModel.assignments /= railsModel_.assignments then
                                Ports.saveRails { uid = user.uid, rails = railsModel_.assignments }

                            else
                                Cmd.none
                    in
                    ( { model_ | state = HasUser user (Running railsModel_) }
                    , Cmd.batch [ Cmd.map RailStackMsg railCmd, saveRails ]
                    )

                _ ->
                    ( model, Cmd.none )


view : Model -> El.Element Msg
view model =
    El.column
        [ El.width El.fill
        , El.height El.fill
        , El.paddingEach { top = 8, left = 8, right = 8, bottom = 0 }
        , El.spacing 4
        , El.htmlAttribute <| Html.Attributes.style "user-select" "none"
        ]
        [ viewHeader model, viewBody model ]


viewBody model =
    case model.state of
        WaitingForUserAuthInfo ->
            El.none

        Failed e ->
            El.none

        HasUser user stateWithUser ->
            viewBodyWithUser user model stateWithUser

        NoUser authState ->
            viewBodyNoUser authState

        TryingSignIn _ ->
            El.none


viewHeader model =
    case model.state of
        WaitingForUserAuthInfo ->
            El.el [ El.centerX ] <| El.text "..."

        Failed e ->
            El.el [ El.centerX ] <| El.text <| "Failure: " ++ e

        HasUser user _ ->
            viewAuthedHeader user model

        NoUser _ ->
            El.el [ El.centerX ] <| El.text "Welcome -- please sign in below"

        TryingSignIn _ ->
            El.el [ El.centerX ] <| El.text <| "Attempting login "


viewAuthedHeader u model =
    El.row [ El.width El.fill ]
        [ El.el [ El.width <| El.px 128 ] <| El.none
        , El.el
            [ El.width El.fill
            , El.centerX
            , Font.center
            ]
          <|
            El.text u.email
        , El.el
            [ El.width <| El.px 128
            ]
          <|
            Input.button [ El.alignRight ]
                { onPress = Just SignOutPressed
                , label =
                    El.el [ Font.underline ] <| El.text "Sign out"
                }
        ]


viewBodyNoUser : AuthPageState -> El.Element Msg
viewBodyNoUser authState =
    El.column
        [ El.centerX
        , El.width (El.fill |> El.maximum 512)
        , Border.width 1
        , El.padding 8
        , El.spacing 8
        ]
        [ El.el [ El.centerX ] <|
            Input.button []
                { onPress = Just GoogleLoginClicked
                , label =
                    El.image []
                        { src = "assets/gsb/btn_google_signin_light_normal_web.png"
                        , description = "Google signin"
                        }
                }
        , El.el [ El.centerX ] <| El.text "or"
        , Input.email [ Border.width 1 ]
            { onChange = EmailInputChanged
            , text = authState.emailInputText
            , placeholder = Just <| Input.placeholder [] <| El.text "foo@example.com"
            , label = Input.labelAbove [] <| El.text "Email address"
            }
        , Input.currentPassword [ Border.width 1 ]
            { onChange = PasswordInputChanged
            , text = authState.passwordInputText
            , placeholder = Just <| Input.placeholder [] <| El.text "password"
            , label = Input.labelAbove [] <| El.text "Password"
            , show = False
            }
        , El.wrappedRow [ El.width El.fill, El.spacing 8 ]
            [ Input.button [ Border.width 1, El.padding 4 ]
                { onPress = Just SignIn
                , label = El.el [ Font.bold ] <| El.text "Sign in"
                }
            , El.text "or"
            , Input.button [ Border.width 1, El.padding 4 ]
                { onPress = Just SignUp
                , label = El.el [ Font.bold ] <| El.text "Sign up"
                }
            ]
        ]


viewBodyWithUser user model stateWithUser =
    case stateWithUser of
        WaitingForRails ->
            El.el [ El.centerX ] <| El.text "..."

        Running railStackModel ->
            RailStack.view railStackModel |> El.map RailStackMsg
